import React, { useCallback, useEffect, useState } from 'react';
import {
  FeatureFlag,
  FeatureFlagState,
  useApi,
} from '@backstage/core-plugin-api';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import List from '@material-ui/core/List';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useAsync from 'react-use/esm/useAsync';
import {
  CustomFeatureFlagsApi,
  customFeatureFlagsApiRef,
} from '@internal/backstage-plugin-custom-feature-flag-common';

import { FeatureFlagItem } from './FeatureFlagItem';

export const sortFlags = (
  flags: FeatureFlag[],
  featureFlagsApi: CustomFeatureFlagsApi,
): FeatureFlag[] => {
  const activeFlags = flags.filter(flag => featureFlagsApi.isActive(flag.name));
  const idleFlags = flags.filter(flag => !featureFlagsApi.isActive(flag.name));
  return [...activeFlags, ...idleFlags];
};

/** @public */
export const UserFeatureFlagsSettings = () => {
  const featureFlagsApi = useApi(customFeatureFlagsApiRef);

  const {
    loading: featureFlagsLoading,
    error: featureFlagsError,
    value: featureFlags,
  } = useAsync(async () => {
    const initialFeatureFlags = await featureFlagsApi.getRegisteredFlagsAsync();
    const initialFeatureFlagsSorted = sortFlags(
      initialFeatureFlags,
      featureFlagsApi,
    );
    return initialFeatureFlagsSorted;
  }, []);

  const [state, setState] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (featureFlags && featureFlags.length > 0) {
      const flagState = Object.fromEntries(
        featureFlags.map(({ name }) => [name, featureFlagsApi.isActive(name)]),
      );
      setState(flagState);
    }
  }, [featureFlags, featureFlagsApi]);

  const toggleFlag = useCallback(
    (flagName: string) => {
      const newState = featureFlagsApi.isActive(flagName)
        ? FeatureFlagState.None
        : FeatureFlagState.Active;

      featureFlagsApi.save({
        states: { [flagName]: newState },
        merge: true,
      });

      setState(prevState => ({
        ...prevState,
        [flagName]: newState === FeatureFlagState.Active,
      }));
    },
    [featureFlagsApi],
  );

  if (featureFlagsLoading) {
    return <Progress />;
  }

  if (featureFlagsError) {
    return (
      <ResponseErrorPanel
        error={featureFlagsError || new Error('Something went wrong.')}
      />
    );
  }

  if (!featureFlags || featureFlags.length === 0) {
    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            No Record Found.
          </Box>
        </Grid>
      </Grid>
    );
  }

  const Header = () => (
    <Grid container style={{ justifyContent: 'space-between' }}>
      <Grid item xs={6} md={8}>
        <Typography variant="h5">Feature Flags</Typography>
        <Typography variant="subtitle1">
          Please refresh the page when toggling feature flags
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <InfoCard title={<Header />}>
      <List dense>
        {featureFlags.map(featureFlag => {
          const enabled = Boolean(state[featureFlag.name]);

          return (
            <FeatureFlagItem
              key={featureFlag.name}
              flag={featureFlag}
              enabled={enabled}
              toggleHandler={toggleFlag}
            />
          );
        })}
      </List>
    </InfoCard>
  );
};
