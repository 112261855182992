export const constants = {
  noRecord: 'No Record Found.',
};

export const ACTIONS = {
  SELECT_TAB: 'SELECT_TAB',
  REFRESH_COUNT: 'REFRESH_COUNT',
  MARK_AS_ALL_READ: 'MARK_AS_ALL_READ',
  SET_FILTER: 'SET_FILTER',
  CLEAR_FILTER: 'CLEAR_FILTER',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER',
} as const;

export const GROUPPICKER = {
  title: 'Groups',

  kind: 'Group',
};

export const CATEGORYPICKER = {
  title: 'Category',

  kind: 'category',
};

export const AUTHORPICKER = {
  title: 'Author',

  kind: 'User',
};
