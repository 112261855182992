import { makeStyles } from '@material-ui/core';

import { Theme } from '@mui/material/styles';

export const useFilterSectionStyles = makeStyles(
  (theme: Theme) => ({
    filterButton: {
      display: 'flex',
      textTransform: 'none',
      color: theme.palette.mode === 'dark' ? '#D9D9D9' : '#333',
      gap: '4px',
      padding: 0,
      '&:hover': {
        backgroundColor: 'inherit',
        boxShadow: 'none',
        textDecoration: 'none',
      },
    },
    filterButtonLabel: {
      color: theme.palette.mode === 'dark' ? '#D9D9D9' : '#333',
      fontSize: '0.875rem',
      lineHeight: 'normal',
    },
    filterButtonIcon: {
      color: theme.palette.text.secondary,
      fontSize: '0.9rem',
    },
    filterMenu: {
      '& .v5-MuiPaper-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: theme.palette.background.default,
        marginTop: '.5rem',
        maxHeight: '15rem',
        minHeight: '15rem',
        overflowY: 'auto',
        minWidth: '12.25rem',

        '& .v5-MuiList-root': {
          padding: '0',
        },
        '& .v5-MuiMenuItem-root': {
          display: 'flex',
          padding: '0.75rem',
          alignItems: 'center',
          gap: '8px',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
        },
        '&::-webkit-scrollbar': {
          backgroundColor: theme.palette.background.default,
          scrollbarWidth: 'thin',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.background.default,
          borderLeft: `1px solid ${theme.palette.divider}`,
        },
        '&::-webkit-scrollbar-thumb': {
          maxWidth: '4px',
          backgroundColor: '#757575',
          borderRadius: '10px',
          border: `2px solid ${theme.palette.background.default}`,
        },
      },
      '& .v5-MuiFormControlLabel-root': {
        margin: '0',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      },
    },
    filterMenuItem: {
      display: 'flex',
      color: theme.palette.text.secondary,
      padding: '0.75rem',
    },
    filterMenuItemLabel: {
      '& .v5-MuiTypography-root': {
        fontSize: '0.875rem',
        color: theme.palette.mode === 'dark' ? '#FFF' : '#333',
      },
      '&  .v5-MuiCheckbox-root': {
        padding: '0px',
      },
    },

    searchInput: {
      '& .v5-MuiInputBase-input': {
        padding: '0px',
        fontSize: '14px',
      },
      '& .v5-MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '& .v5-MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '& .v5-MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },

    searchItem: {
      display: 'flex',
      padding: '8px 0px',
      gap: '8px',
      alignItems: 'center',
      '& .v5-MuiSvgIcon-root': {
        color: '#C4C4C4',
      },
    },
    divider: {
      margin: '0px !important',
    },
  }),
  { name: 'CustomFilterSection' },
);
