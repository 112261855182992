import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import {
  ArrowIcon,
  CustomCheckedIcon,
  CustomUncheckedIcon,
  SearchIcon,
} from '../../../assets/Icons';
import { AnnouncementContext } from '../state/announcement.state';
import { useFilterSectionStyles } from '../styles/custom-filter-section.styles';
import { ACTIONS } from '../constants/announcement.constant';
import ClearActiveButton from './ClearActiveButton';
import { FilterOption } from '../types/announcement.types';

type CustomFilterSectionProps = {
  label: string;
  path: string;
  filterOptions: FilterOption[];
  selectedValues?: string[];
  isSearchEnabled?: boolean;
};

export const CustomFilterSection = (props: CustomFilterSectionProps) => {
  const {
    label,
    path,
    filterOptions,
    selectedValues = [],
    isSearchEnabled = false,
  } = props;
  const { dispatch } = useContext(AnnouncementContext);
  const classes = useFilterSectionStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategories, setSelectedCategories] =
    useState<string[]>(selectedValues);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(prev => (!!prev ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchQuery('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (filter: string, value: string) => {
    const updatedSelection = selectedCategories.includes(value)
      ? selectedCategories.filter(item => item !== value)
      : [...selectedCategories, value];

    setSelectedCategories(updatedSelection);

    if (updatedSelection.length === 0) {
      dispatch({
        type: ACTIONS.CLEAR_FILTER,
        payload: { filter },
      });
    } else {
      dispatch({
        type: ACTIONS.SET_FILTER,
        payload: { filter, values: updatedSelection },
      });
    }
  };

  const handleClearCategories = () => {
    setSelectedCategories([]);
    dispatch({
      type: ACTIONS.CLEAR_FILTER,
      payload: { filter: path },
    });
  };
  // const selectOptions = filterOptions?.map(filterOption => ({

  //   label: filterOption.label,
  //   value: filterOption.value

  // }));
  const filteredOptions = filterOptions
    ? filterOptions.filter(item =>
        isSearchEnabled && searchQuery
          ? item.label.toLowerCase().includes(searchQuery.toLowerCase())
          : true,
      )
    : [];

  if (!filterOptions?.length) {
    return null;
  }

  return (
    <div>
      <Button
        aria-controls={open ? 'filter-section-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.filterButton}
        aria-label={`${label} filter`}
      >
        <Typography className={classes.filterButtonLabel}>{label}</Typography>
        {selectedCategories.length > 0 && !open && (
          <ClearActiveButton
            activeItems={`${selectedCategories.length}`}
            onClear={handleClearCategories}
          />
        )}
        <ArrowIcon />
      </Button>
      <Menu
        data-testid="filter-section-menu"
        id="filter-section-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.filterMenu}
      >
        {isSearchEnabled && (
          <Box>
            <MenuItem>
              <Box className={classes.searchItem}>
                <SearchIcon />
                <TextField
                  placeholder="Search"
                  variant="standard"
                  size="small"
                  fullWidth
                  value={searchQuery}
                  onChange={handleInputChange}
                  className={classes.searchInput}
                  onKeyDown={event => {
                    event.stopPropagation();
                  }}
                />
              </Box>
            </MenuItem>
            <Divider className={classes.divider} />
          </Box>
        )}
        {filteredOptions.map(item => (
          <MenuItem key={item.value} className={classes.filterMenuItem}>
            <FormControlLabel
              className={classes.filterMenuItemLabel}
              control={
                <Checkbox
                  checked={selectedCategories?.includes(item.value)}
                  onChange={() => handleCategoryChange(path, item.value)}
                  name={item.value}
                  icon={<CustomUncheckedIcon />}
                  checkedIcon={<CustomCheckedIcon />}
                />
              }
              label={item.label}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
