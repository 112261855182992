import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useDeleteDialogStyles = makeStyles(
  (theme: Theme) => ({
    removeEntityDialog: {
      '& .MuiPaper-root': {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        color: theme.palette.mode === 'dark' ? '#fff' : '#333',
        backgroundColor: theme.palette.mode === 'dark' ? '#404040' : '#fff',
        padding: '1rem',
        borderRadius: '16px',
      },
      '& .MuiDialogContent-root': {
        padding: '0',
      },
      '&__headerContainer': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: '1rem',
        paddingLeft: '1rem',
        paddingTop: '1rem',
      },
      '&__title': {
        fontSize: '1.5rem',
        padding: '0',
      },
      '&__content': {
        color: theme.palette.mode === 'dark' ? '#fff' : '#333',
        fontSize: '.875rem',
        maxWidth: '28rem',
      },
      '&__actions': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0',
        paddingRight: '1rem',
        paddingLeft: '1rem',
        paddingBottom: '1rem',
      },
      '&__btn': {
        borderRadius: '100px',
        padding: '.5rem 3rem',
        borderColor: '#9E9E9E',
        color: theme.palette.mode === 'dark' ? '#fff' : '#333',
        textTransform: 'none',
        fontSize: '.75rem',
        fontWeight: 'bold',
        '&:hover': {
          borderColor: '#9E9E9E',
          backgroundColor: 'inherit',
        },
        '&--delete': {
          backgroundColor: '#EE4C40',
          border: 'none',
          boxShadow: 'none',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#EE4C40',
            boxShadow: 'none',
          },
        },
      },
    },
  }),
  { name: 'DeleteDialog' },
);
