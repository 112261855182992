import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { announcementsApiRef } from '../apis';
import {
  AnnouncementsFilters,
  AnnouncementsList,
  NAVIGATION_ITEMS,
} from '@internal/backstage-plugin-announcements-common';
import useAsyncRetry from 'react-use/esm/useAsyncRetry';
import { useMemo, useState } from 'react';

/**
 * Options for the useAnnouncements hook
 *
 * @public
 */
export type AnnouncementsOptions = {
  dependencies?: any[];
};

/**
 * Hook to retrieve list of announcements.
 *
 * @returns A list of announcements and loading state
 *
 * @public
 */
export const useAnnouncements = (
  props: AnnouncementsFilters,
  options?: AnnouncementsOptions,
) => {
  const api = useApi(announcementsApiRef);
  const identityApi = useApi(identityApiRef);

  // Store all fetched announcements
  const [allAnnouncements, setAllAnnouncements] =
    useState<AnnouncementsList | null>(null);
  const [userEntityRef, setUserEntityRef] = useState<string | null>(null);
  const [userGroups, setUserGroups] = useState<string[]>([]);

  const { loading, error, retry } = useAsyncRetry(async () => {
    const [userIdentity, fetchedAnnouncements] = await Promise.all([
      identityApi.getBackstageIdentity(),
      api.announcements(props),
    ]);

    setUserEntityRef(userIdentity.userEntityRef);
    setUserGroups(userIdentity.ownershipEntityRefs);
    setAllAnnouncements(fetchedAnnouncements);
  }, [api, identityApi, ...(options?.dependencies ?? [])]);

  // Filter announcements based on tab search query and selected filters
  const filteredResults = useMemo(() => {
    if (!allAnnouncements) return { count: 0, results: [] };

    let results = allAnnouncements.results;

    if (props.selectedTab === NAVIGATION_ITEMS.XELERATE_UPDATES.value) {
      results = results.filter(
        a => a.publisherGroup === NAVIGATION_ITEMS.XELERATE_UPDATES.value,
      );
    }
    if (
      props.selectedTab === NAVIGATION_ITEMS.MY_UPDATES.value &&
      userEntityRef &&
      userGroups
    ) {
      results = results.filter(
        a =>
          a.publisher === userEntityRef ||
          (a.publisherGroup && userGroups.includes(a.publisherGroup)),
      );
    }
    if (props.searchQuery) {
      const searchLower = props.searchQuery.toLowerCase();
      results = results.filter(
        a =>
          a.title?.toLowerCase().includes(searchLower) ||
          a.excerpt?.toLowerCase().includes(searchLower) ||
          a.publisherGroup?.toLowerCase().includes(searchLower) ||
          a.category?.title.toLocaleLowerCase().includes(searchLower),
      );
    }

    if (props.selectedFilters) {
      const { Group, category, User } = props.selectedFilters;

      // filter for groups
      if (Group?.length > 0) {
        results = results.filter(resultEntity =>
          Group.some(group => resultEntity.publisherGroup === group),
        );
      }
      // filter for category

      if (category?.length > 0) {
        results = results.filter(resultEntity =>
          category.some(cat => resultEntity.category?.slug === cat),
        );
      }
      // filter for user

      if (User?.length > 0) {
        results = results.filter(resultEntity =>
          User.some(user => resultEntity.publisher === user),
        );
      }
    }

    return { count: results.length, results };
  }, [
    props.selectedTab,
    props.searchQuery,
    props.selectedFilters,
    allAnnouncements,
    userEntityRef,
    userGroups,
  ]);

  return {
    announcements: filteredResults,
    loading,
    error,
    retry,
  };
};
