import {
  FeatureFlagState,
  FeatureFlag,
  FeatureFlagsSaveOptions,
  DiscoveryApi,
  FetchApi,
  StorageApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { CustomFeatureFlagsApi } from '@internal/backstage-plugin-custom-feature-flag-common';

export function validateFlagName(name: string): void {
  if (name.length < 3) {
    throw new Error(
      `The '${name}' feature flag must have a minimum length of three characters.`,
    );
  }

  if (name.length > 150) {
    throw new Error(
      `The '${name}' feature flag must not exceed 150 characters.`,
    );
  }

  if (!name.match(/^[a-z]+[a-z0-9-]+$/)) {
    throw new Error(
      `The '${name}' feature flag must start with a lowercase letter and only contain lowercase letters, numbers and hyphens. ` +
        'Examples: feature-flag-one, alpha, release-2020',
    );
  }
}

// interface CustomFeatureFlagsApi extends FeatureFlagsApi {
//   /**
//    * Registers a new feature flag. Once a feature flag has been registered it
//    * can be toggled by users, and read back to enable or disable features.
//    */
//   // registerFlag(flag: FeatureFlag): void;
//   /**
//    * Get a list of all registered flags.
//    */
//   // getRegisteredFlags(): FeatureFlag[];
//   /**
//    * Whether the feature flag with the given name is currently activated for the user.
//    */
//   // isActive(name: string): boolean;
//   /**
//    * Whether the feature flag with the given name is currently activated for the user.
//    */
//   isFlagExist(name: string): boolean | undefined;
//   /**
//    * Save the user's choice of feature flag states.
//    */
//   // save(options: FeatureFlagsSaveOptions): void;
// }

/**
 * A feature flags implementation that stores the flags in the browser's local
 * storage.
 *
 * @public
 */
export class StorageFeatureFlags implements CustomFeatureFlagsApi {
  private registeredFeatureFlags: FeatureFlag[] = [];
  private flags?: Map<string, FeatureFlagState>;
  private ffBucketName = 'xelerateFeatureFlag';
  private ffDBBucketName = 'default.xelerateFeatureFlag';
  private ffKeyName = 'customFeatureFlag';

  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly storageApi: StorageApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    storageApi: StorageApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.storageApi = options.storageApi.forBucket(this.ffBucketName);
  }

  registerFlag(flag: FeatureFlag) {
    validateFlagName(flag.name);
    this.registeredFeatureFlags.push(flag);
  }

  getRegisteredFlags(): FeatureFlag[] {
    if (!this.flags) {
      this.load();
    }
    return this.registeredFeatureFlags.slice();
  }

  async getRegisteredFlagsAsync(): Promise<FeatureFlag[]> {
    if (!this.flags) {
      await this.load();
    }
    return this.registeredFeatureFlags.slice();
  }

  isActive(name: string): boolean {
    if (!this.flags) {
      this.load();
    }
    return (this.flags || new Map()).get(name) === FeatureFlagState.Active;
  }

  async isFlagActive(name: string): Promise<boolean | undefined> {
    if (!this.flags) {
      await this.load();
    }
    const isExist = (this.flags || new Map()).get(name);
    if (isExist !== undefined) {
      return isExist === FeatureFlagState.Active;
    }
    return isExist;
  }

  async save(options: FeatureFlagsSaveOptions): Promise<void> {
    if (!this.flags) {
      this.load();
    }
    for (const [name, state] of Object.entries(options.states)) {
      this.flags?.set(name, state);
    }
    const data = Object.fromEntries(this.flags || new Map());
    await this.storageApi.set(this.ffKeyName, data);
  }

  private async load() {
    try {
      this.flags = await this.get();
    } catch {
      this.flags = new Map();
    }
  }

  private async get(): Promise<Map<string, FeatureFlagState>> {
    const fetchUrl = await this.getFetchUrl();
    const response = await this.fetchApi.fetch(fetchUrl);

    if (response.status === 404) {
      return new Map();
    }

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    try {
      const { value: rawValue } = await response.json();
      const value = JSON.parse(JSON.stringify(rawValue)) as unknown;
      if (typeof value !== 'object' || value === null) {
        return new Map();
      }

      const data = Object.entries(value);
      return new Map(data);
    } catch {
      // If the value is not valid JSON, we return an unknown presence. This should never happen
      return new Map();
    }
  }

  private async getFetchUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('user-settings');
    const encodedBucketName = encodeURIComponent(this.ffDBBucketName);
    const encodedKey = encodeURIComponent(this.ffKeyName);
    return `${baseUrl}/buckets/${encodedBucketName}/keys/${encodedKey}`;
  }
}
