import {
  ApiRef,
  createApiRef,
  FeatureFlag,
  FeatureFlagsApi,
} from '@backstage/core-plugin-api';

/***/
/**
 * Common functionalities for the custom-feature-flag plugin.
 *
 * @packageDocumentation
 */

/**
 * In this package you might for example declare types that are common
 * between the frontend and backend plugin packages.
 */
// export type CommonType = {
//   field: string;
// };

/**
 * Or you might declare some common constants.
 */
// export const COMMON_CONSTANT = 1;

export interface CustomFeatureFlagsApi extends FeatureFlagsApi {
  /**
   * Get a list of all registered flags asynchronus and load data from DB.
   */
  getRegisteredFlagsAsync(): Promise<FeatureFlag[]>;
  /**
   * Whether the feature flag with the given name is exist | true | false for the user.
   */
  isFlagActive(name: string): Promise<boolean | undefined>;
}

export const customFeatureFlagsApiRef: ApiRef<CustomFeatureFlagsApi> =
  createApiRef({
    id: 'core.featureflags',
  });

export * from './StorageFeatureFlags';
