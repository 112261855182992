import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const DotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
  >
    <circle cx="2" cy="2" r="2" fill="#ABABAB" />
  </svg>
);
export const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M2.66667 11.1117V13.3337H4.88858L11.4417 6.78049L9.21983 4.55857L2.66667 11.1117ZM13.16 5.06221C13.3911 4.83113 13.3911 4.45785 13.16 4.22677L11.7736 2.84029C11.7187 2.78536 11.6536 2.74177 11.582 2.71204C11.5103 2.6823 11.4334 2.66699 11.3558 2.66699C11.2782 2.66699 11.2014 2.6823 11.1297 2.71204C11.058 2.74177 10.9929 2.78536 10.9381 2.84029L9.85382 3.92459L12.0757 6.1465L13.16 5.06221Z"
      fill="currentcolor"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.66667 14C4.3 14 3.98611 13.8694 3.725 13.6083C3.46389 13.3472 3.33333 13.0333 3.33333 12.6667V4H2.66667V2.66667H6V2H10V2.66667H13.3333V4H12.6667V12.6667C12.6667 13.0333 12.5361 13.3472 12.275 13.6083C12.0139 13.8694 11.7 14 11.3333 14H4.66667ZM11.3333 4H4.66667V12.6667H11.3333V4ZM6 11.3333H7.33333V5.33333H6V11.3333ZM8.66667 11.3333H10V5.33333H8.66667V11.3333Z"
      fill="currentcolor"
    />
  </svg>
);
export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M6.4 19.5L5 18.1L10.6 12.5L5 6.9L6.4 5.5L12 11.1L17.6 5.5L19 6.9L13.4 12.5L19 18.1L17.6 19.5L12 13.9L6.4 19.5Z"
      fill="#F7F6FE"
    />
  </svg>
);
export const BackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.6665 15L6.6665 10L11.6665 5L12.8332 6.16667L8.99984 10L12.8332 13.8333L11.6665 15Z"
      fill="currentcolor"
    />
  </svg>
);

export const VerticalDivider = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="18"
    viewBox="0 0 2 18"
    fill="none"
    sx={{
      height: '16px',
      padding: '0px',
      width: '4px',
    }}
  >
    <path d="M1 1L0.999999 17" stroke="#616161" strokeLinecap="square" />
  </SvgIcon>
);

export const MarkAllReadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.6 7.2H4V18.4C4 19.28 4.72 20 5.6 20H16.8V18.4H5.6V7.2ZM18.4 4H8.8C7.92 4 7.2 4.72 7.2 5.6V15.2C7.2 16.08 7.92 16.8 8.8 16.8H18.4C19.28 16.8 20 16.08 20 15.2V5.6C20 4.72 19.28 4 18.4 4ZM17.6 11.2H9.6V9.6H17.6V11.2ZM14.4 14.4H9.6V12.8H14.4V14.4ZM17.6 8H9.6V6.4H17.6V8Z"
      fill="currentColor"
    />
  </svg>
);

export const CustomCheckedIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    sx={{
      width: 16,
      height: 16,
      padding: '3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      border: '1px solid var(--Future-Green-500, #2EFFAF)',
      background: 'var(--Future-Green-500, #2EFFAF)',
    }}
  >
    <path
      d="M3.79743 7.99988L0.999939 5.15586L1.69931 4.44486L3.79743 6.57787L8.3004 2L8.99978 2.711L3.79743 7.99988Z"
      // fill="#F7F6FE"
      fill="#383838"
    />
  </SvgIcon>
);

export const CustomUncheckedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: '16px',
      height: '16px',
      borderRadius: '4px',
      border: '1px solid var(--UI-Gray-60, #9E9E9E)',
      background: 'transparent',
    }}
  />
);

export const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M9.74994 10.7503L5.91661 6.91699L4.74994 8.08366L9.74994 13.0837L14.7499 8.08366L13.5833 6.91699L9.74994 10.7503Z"
      fill="currentColor"
    />
  </svg>
);

export const ClearIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M3.73341 11.0832L2.91675 10.2665L6.18341 6.99984L2.91675 3.73317L3.73341 2.9165L7.00008 6.18317L10.2667 2.9165L11.0834 3.73317L7.81675 6.99984L11.0834 10.2665L10.2667 11.0832L7.00008 7.8165L3.73341 11.0832Z"
      fill="#9E9E9E"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.7556 16L9.15556 10.4C8.71111 10.7556 8.2 11.037 7.62222 11.2444C7.04444 11.4519 6.42963 11.5556 5.77778 11.5556C4.16296 11.5556 2.7963 10.9963 1.67778 9.87778C0.559259 8.75926 0 7.39259 0 5.77778C0 4.16296 0.559259 2.7963 1.67778 1.67778C2.7963 0.559259 4.16296 0 5.77778 0C7.39259 0 8.75926 0.559259 9.87778 1.67778C10.9963 2.7963 11.5556 4.16296 11.5556 5.77778C11.5556 6.42963 11.4519 7.04444 11.2444 7.62222C11.037 8.2 10.7556 8.71111 10.4 9.15556L16 14.7556L14.7556 16ZM5.77778 9.77778C6.88889 9.77778 7.83333 9.38889 8.61111 8.61111C9.38889 7.83333 9.77778 6.88889 9.77778 5.77778C9.77778 4.66667 9.38889 3.72222 8.61111 2.94444C7.83333 2.16667 6.88889 1.77778 5.77778 1.77778C4.66667 1.77778 3.72222 2.16667 2.94444 2.94444C2.16667 3.72222 1.77778 4.66667 1.77778 5.77778C1.77778 6.88889 2.16667 7.83333 2.94444 8.61111C3.72222 9.38889 4.66667 9.77778 5.77778 9.77778Z"
      fill="#C4C4C4"
    />
  </svg>
);

export const UpdatesIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V7.5H12.5V4.16667H4.16667V15.8333ZM5.83333 14.1667H14.1667V12.5H5.83333V14.1667ZM5.83333 7.5H10V5.83333H5.83333V7.5ZM5.83333 10.8333H14.1667V9.16667H5.83333V10.8333Z"
    />
  </SvgIcon>
);
