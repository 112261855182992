import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useAnnoucementSubtitle = makeStyles(
  (theme: Theme) => ({
    annouementcardSubtitle: {
      color:
        theme.palette.mode === 'light'
          ? 'var(--UI-Gray-45, #757575)'
          : 'var(--UI-Gray-75, #BDBDBD)',
      // fontSize: '0.75rem',

      lineHeight: '18px',
      fontWeight: 400,
      '&__links': {
        // fontSize: '0.75rem',
        lineHeight: '18px',
        fontWeight: 400,
        color:
          theme.palette.mode === 'light'
            ? 'var(--UI-Gray-45, #757575)'
            : 'var(--UI-Gray-75, #BDBDBD)',
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        textDecorationSkipInk: 'auto',
        textDecorationThickness: 'auto',
        textUnderlineOffset: 'auto',
        textUnderlinePosition: 'from-font',
        '&:hover': {
          color: '#2EFFAF',
        },
      },
    },
  }),
  { name: 'AnnoucementSubtitle' },
);
