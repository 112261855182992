import { usePermission } from '@backstage/plugin-permission-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { announcementDeletePermission } from '@internal/backstage-plugin-announcements-common';
import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useDeleteDialogStyles } from './styles/delete-annocement-dialog.styles';
import { CloseIcon } from '../../assets/Icons';

type DeleteAnnouncementDialogProps = {
  open: boolean;
  onConfirm: () => any;
  onCancel: () => any;
  announcementTitle?: string;
};

export const DeleteAnnouncementDialog = (
  props: DeleteAnnouncementDialogProps,
) => {
  const { open, onConfirm, onCancel, announcementTitle } = props;
  const classes = useDeleteDialogStyles();
  usePermission({
    permission: announcementDeletePermission,
  });
  // const { t } = useAnnouncementsTranslation();

  return (
    // <Dialog open={open} onClose={onCancel}>
    //   <DialogTitle>{t('deleteDialog.title')}</DialogTitle>
    //   <DialogActions>
    //     <Button onClick={onCancel}>{t('deleteDialog.cancel')}</Button>

    //     <Button
    //       disabled={loadingDeletePermission || !canDeleteAnnouncement}
    //       onClick={onConfirm}
    //       color="secondary"
    //     >
    //       {t('deleteDialog.delete')}
    //     </Button>
    //   </DialogActions>
    // </Dialog>
    <Dialog
      open={open}
      onClose={onCancel}
      className={classes.removeEntityDialog}
    >
      <Box className={`${classes.removeEntityDialog}__headerContainer`}>
        <DialogTitle className={`${classes.removeEntityDialog}__title`}>
          Deleting Update
        </DialogTitle>
        <IconButton aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText className={`${classes.removeEntityDialog}__content`}>
          You are about to delete {announcementTitle}. Proceed with caution,
          because others will lose access to it.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={`${classes.removeEntityDialog}__actions`}>
        <Button
          className={`${classes.removeEntityDialog}__btn`}
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={`${classes.removeEntityDialog}__btn ${classes.removeEntityDialog}__btn--delete`}
          onClick={onConfirm}
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
