import { useTranslationRef } from '@backstage/core-plugin-api/alpha';
import { announcementsTranslationRef } from '../translation';

/**
 * Hook to get the announcements translation API.
 *
 * @returns The announcements translation API
 *
 * @public
 */
export const useAnnouncementsTranslation = () => {
  return useTranslationRef(announcementsTranslationRef);
};
