import React, { useContext } from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';

import { DeleteAnnouncementDialog } from './DeleteAnnouncementDialog';
import { useDeleteAnnouncementDialogState } from './useDeleteAnnouncementDialogState';
import {
  announcementsApiRef,
  useAnnouncements,
  useAnnouncementsTranslation,
} from '@internal/backstage-plugin-announcements-react';
import { Alert } from '@material-ui/lab';

import Grid from '@mui/material/Grid';
import { AnnouncementCard } from './AnnouncementCard';
import { AnnouncementContext } from './state/announcement.state';
import { ACTIONS, constants } from './constants/announcement.constant';

import CustomAccordion from './CustomAccordian';
import Box from '@mui/material/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DateTime } from 'luxon';
import { useAnnoucementsGridStyles } from './styles/annoucement-grid.styles';

export const AnnouncementsGrid = ({
  category,
  cardTitleLength,
  active,
  group,
}: {
  maxPerPage: number;
  category?: string;
  cardTitleLength?: number;
  active?: boolean;
  group?: string;
}) => {
  const classes = useAnnoucementsGridStyles();
  const announcementsApi = useApi(announcementsApiRef);
  const alertApi = useApi(alertApiRef);

  // const [page, setPage] = React.useState(1);
  // const handleChange = (_event: any, value: number) => {
  //   setPage(value);
  // };
  const {
    state: { selectedTab, searchFilter, selectedFilters },
    dispatch,
  } = useContext(AnnouncementContext);

  const updatedFilters = {
    ...selectedFilters,
    Group: group
      ? [...(selectedFilters.Group || []), group]
      : [...(selectedFilters.Group || [])],
  };

  const {
    announcements,
    loading,
    error,
    retry: refresh,
  } = useAnnouncements(
    {
      category,
      active,
      selectedTab,
      searchQuery: searchFilter,
      selectedFilters: updatedFilters,
    },
    { dependencies: [category] },
  );

  const { t } = useAnnouncementsTranslation();

  const {
    isOpen: isDeleteDialogOpen,
    open: openDeleteDialog,
    close: closeDeleteDialog,
    announcement: announcementToDelete,
  } = useDeleteAnnouncementDialogState();

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '15rem',
        }}
      >
        <CircularProgress data-testid="loading-entities" size="5rem" />
      </Box>
    );
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const onCancelDelete = () => {
    closeDeleteDialog();
  };
  const onConfirmDelete = async () => {
    closeDeleteDialog();
    const lastSeen = announcementsApi.lastSeenDate();
    const announcementCreatedAt = DateTime.fromISO(
      announcementToDelete!.created_at,
    );
    if (announcementCreatedAt > lastSeen) {
      announcementsApi.markLastSeenDate(announcementCreatedAt);
    }
    try {
      await announcementsApi.deleteAnnouncementByID(announcementToDelete!.id);

      alertApi.post({
        message: t('announcementsPage.grid.announcementDeleted'),
        severity: 'success',
      });
      dispatch({ type: ACTIONS.REFRESH_COUNT });
    } catch (err) {
      alertApi.post({ message: (err as Error).message, severity: 'error' });
    }

    refresh();
  };

  const now = DateTime.now();
  const startOfWeek = now.startOf('week');

  const endOfWeek = now.endOf('week');

  const thisWeekAnnouncements = announcements.results.filter(
    announcement =>
      DateTime.fromISO(announcement.created_at) >= startOfWeek &&
      DateTime.fromISO(announcement.created_at) <= endOfWeek,
  );

  const olderAnnouncements = announcements.results.filter(
    announcement => DateTime.fromISO(announcement.created_at) < startOfWeek,
  );
  return (
    <>
      {announcements.results.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: '15rem',
          }}
        >
          {constants.noRecord}
        </Box>
      ) : (
        <div className={classes.gridContainerWrapper}>
          {/* This Week Announcements */}
          {thisWeekAnnouncements.length > 0 && (
            <CustomAccordion title="This Week">
              <Grid container className={classes.cardContainer}>
                {thisWeekAnnouncements.map(announcement => (
                  <Grid item xs={12} sm={6} md={4} key={announcement.id}>
                    <AnnouncementCard
                      announcement={announcement}
                      onDelete={() => openDeleteDialog(announcement)}
                      options={{ titleLength: cardTitleLength }}
                    />
                  </Grid>
                ))}
              </Grid>
            </CustomAccordion>
          )}

          {/* Older Announcements */}
          {olderAnnouncements.length > 0 && (
            <CustomAccordion title="Older">
              <Grid container className={classes.cardContainer}>
                {olderAnnouncements.map(announcement => (
                  <Grid item xs={12} sm={6} md={4} key={announcement.id}>
                    <AnnouncementCard
                      announcement={announcement}
                      onDelete={() => openDeleteDialog(announcement)}
                      options={{ titleLength: cardTitleLength }}
                    />
                  </Grid>
                ))}
              </Grid>
            </CustomAccordion>
          )}
        </div>
      )}

      <DeleteAnnouncementDialog
        open={isDeleteDialogOpen}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        announcementTitle={announcementToDelete?.title}
      />
    </>
  );
};
