import React from 'react';

import { CustomFilterSection } from './CustomFilterSection';
import { CATEGORYPICKER } from '../constants/announcement.constant';
import { FilterOption } from '../types/announcement.types';

interface CategoryPickerProps {
  filterOptions: FilterOption[];
}

export const CategoryPicker = ({ filterOptions }: CategoryPickerProps) => {
  return (
    <CustomFilterSection
      label={CATEGORYPICKER.title}
      path={CATEGORYPICKER.kind}
      filterOptions={filterOptions || []}
      isSearchEnabled
    />
  );
};
