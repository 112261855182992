/**
 * Main topic for announcement events
 *
 * @public
 */
export const EVENTS_TOPIC_ANNOUNCEMENTS = 'announcements';

/**
 * Event action for creating an announcement
 *
 * @public
 */
export const EVENTS_ACTION_CREATE_ANNOUNCEMENT = 'create_announcement';

/**
 * Event action for updating an announcement
 *
 * @public
 */
export const EVENTS_ACTION_UPDATE_ANNOUNCEMENT = 'update_announcement';

/**
 * Event action for deleting an announcement
 *
 * @public
 */
export const EVENTS_ACTION_DELETE_ANNOUNCEMENT = 'delete_announcement';

/**
 * Event action for creating a category
 *
 * @public
 */
export const EVENTS_ACTION_CREATE_CATEGORY = 'create_category';

/**
 * Event action for deleting a category
 *
 * @public
 */
export const EVENTS_ACTION_DELETE_CATEGORY = 'delete_category';

/**
 * Channel name for new announcement signals
 *
 * @public
 */
export const SIGNALS_CHANNEL_ANNOUNCEMENTS = 'announcements:new';

/**
 * Channel name for new announcement signals
 *
 * @public
 */
export const RESOURCE_TYPE_ANNOUNCEMENT_ENTITY = 'announcement';

/**
 * navitems name
 *
 * @public
 */
export const NAVIGATION_ITEMS = {
  ALL_UPDATES: { label: 'All Updates', value: 'all-updates' },
  XELERATE_UPDATES: { label: 'XELERATE', value: 'group:default/admins' },
  MY_UPDATES: { label: 'My Updates', value: 'my-updates' },
};
