import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useAnnoucementCardStyles = makeStyles(
  (theme: Theme) => ({
    announcementcard: {
      display: 'flex',
      padding: '16px',
      flexDirection: 'column',
      // alignItems: 'inherit',
      gap: '12px',
      borderRadius: '8px',
      // background: '#474747',
      background:
        theme.palette.mode === 'light' ? 'var(--UI-White, #FFF)' : '#474747',
      height: '100%',
      flexGrow: 1,
      cursor: 'pointer',
      '&:hover $announcementcardHeaderActions': {
        opacity: 1,
        visibility: 'visible',
      },
    },
    announcementcardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    announcementcardHeaderName: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
      flex: 'auto',
    },
    announcementcardHeaderActions: {
      display: 'flex',
      gap: '8px',
      opacity: 0,
      visibility: 'hidden',
      transition: 'opacity 0.3s ease, visibility 0.3s ease',
    },
    actionButtons: {
      color: theme.palette.mode === 'light' ? '#333333' : 'white',
    },
    announcementcardContent: {
      display: 'flex',
      flex: 'auto',
    },
    announcementcardFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    announcementcardFooterDetails: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    announcementcardSubtitle: {
      color:
        theme.palette.mode === 'light'
          ? 'var(--UI-Gray-45, #757575)'
          : 'var(--UI-Gray-75, #BDBDBD)',
      // fontSize: '0.75rem',

      lineHeight: '18px',
      fontWeight: 400,
      '&__links': {
        // fontSize: '0.75rem',
        lineHeight: '18px',
        fontWeight: 400,
        color:
          theme.palette.mode === 'light'
            ? 'var(--UI-Gray-45, #757575)'
            : 'var(--UI-Gray-75, #BDBDBD)',
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        textDecorationSkipInk: 'auto',
        textDecorationThickness: 'auto',
        textUnderlineOffset: 'auto',
        textUnderlinePosition: 'from-font',
      },
    },
    announcementcardDate: {
      // color: 'var(--UI-Gray-75, #BDBDBD)',
      color:
        theme.palette.mode === 'light'
          ? 'var(--UI-Gray-45, #757575)'
          : 'var(--UI-Gray-75, #BDBDBD)',
      fontSize: '0.75rem',
      lineHeight: '18px',
      fontWeight: 400,
    },
    announcementcardFooterChip: {
      display: 'flex',
      padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '100px',
      background:
        theme.palette.mode === 'light'
          ? 'var(--UI-Gray-90, #EEE)'
          : 'var(--UI-Gray-40, #616161)',
    },
    announcementcardFooterChipText: {
      color:
        theme.palette.mode === 'dark'
          ? ' #2EFFAF'
          : 'var(--Future-Green-800, #00A868)',

      fontSize: '12px',
    },
    groupName: {
      fontSize: '0.875rem',
      color:
        theme.palette.mode === 'light'
          ? 'var(--UI-Gray-45, #757575)'
          : 'var(--UI-Gray-75, #BDBDBD)',
    },
    cardButtons: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },

    announcementcardHeaderTitle: {
      fontWeight: 700,
      fontSize: '1rem',
      fontStyle: 'normal',
      overflow: 'hidden',
      marginBottom: '0',
      '& a': {
        color: 'inherit',
      },
    },
    announcementcardbody: {
      // minHeight: '2.5rem',
      fontSize: '0.875rem',
      display: 'flex',

      // WebkitLineClamp: 2,
      // WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      // textOverflow: 'ellipsis',
      fontWeight: 400,
      color:
        theme.palette.mode === 'light'
          ? 'var(--UI-Gray-20, #333)'
          : 'var(--UI-White, #FFF)',
    },
  }),
  { name: 'AnnoucementCard' },
);
