import { useContext, useState } from 'react';
import { AnnouncementContext } from '../state/announcement.state';
import { useSearchFilterStyles } from '../styles/search-filter.styles';
import { useDebounce } from 'react-use';
import { ACTIONS } from '../constants/announcement.constant';
import React from 'react';
import { Textfield } from '../../AnnouncementForm/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export const SearchFilter = () => {
  const { dispatch } = useContext(AnnouncementContext);
  const [search, setSearch] = useState('');
  const classes = useSearchFilterStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useDebounce(
    () => {
      dispatch({
        type: ACTIONS.SET_SEARCH_FILTER,
        payload: search.trim(),
      });
    },
    250,
    [search],
  );

  return (
    <div className={classes.SearchWrapper}>
      <Textfield
        name="search"
        placeholder="Search"
        onChange={handleChange}
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
        hideLabel
        autoComplete="off"
      />
    </div>
  );
};
