import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useFilterSectionWrapperStyles = makeStyles(
  (theme: Theme) => ({
    filterSectionContainer: {
      display: 'flex',
      padding: '0 2rem 0 1rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      borderBottom:
        theme.palette.mode === 'light'
          ? '1px solid var(--UI-Gray-85, #D9D9D9)'
          : '1px solid var(--UI-Gray-40, #616161)',

      '& .filterSection': {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        padding: '.75rem 0 1rem 0',
      },
    },
  }),
  { name: 'FilterSectionWrapper' },
);
