import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useSearchFilterStyles = makeStyles(
  (theme: Theme) => ({
    SearchWrapper: {
      '& .v5-MuiTextField-root': {
        backgroundColor: theme.palette.mode === 'dark' ? '#383838' : '#D9D9D9',
      },

      '& .v5-MuiOutlinedInput-root': {
        padding: '.5rem .75rem !important',

        '& .v5-MuiInputAdornment-root': {
          marginRight: '.25rem',
        },

        '& .v5-MuiOutlinedInput-input': {
          padding: '0',
        },
      },
    },
  }),
  { name: 'SearchFilter' },
);
