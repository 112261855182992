import { createReactExtension } from '@backstage/core-plugin-api';
import { createCardExtension, homePlugin } from '@backstage/plugin-home';

/* export const DashboardStarredEntities = homePlugin.provide(
  createReactExtension({
    name: 'Favorites',
    component: {
      lazy: () =>
        import('@backstage/plugin-home').then(m => m.HomePageStarredEntities),
    },
    data: {
      'home.widget.config': {
        layout: {
          width: {
            defaultColumns: 6,
            minColumns: 4,
          },
          height: {
            defaultRows: 6,
            minRows: 6,
          },
        },
      },
    },
  }),
); */

// * Using cusotm favorites to get better styling on text and links
export const DashboardFavorites = homePlugin.provide(
  createCardExtension({
    name: 'DashboardFavorites',
    title: 'Favorites',
    components: () => import('./widgets/StarredEntities'),
    layout: {
      height: { minRows: 6, defaultRows: 6 },
      width: { minColumns: 4, defaultColumns: 6 },
    },
  }),
);

export const DashboardRecentlyVisited = homePlugin.provide(
  createReactExtension({
    name: 'Recently Visited',
    component: {
      lazy: () =>
        import('@backstage/plugin-home').then(m => m.HomePageRecentlyVisited),
    },
    data: {
      'home.widget.config': {
        layout: {
          width: {
            defaultColumns: 6,
            minColumns: 4,
          },
          height: {
            defaultRows: 6,
            minRows: 6,
          },
        },
      },
    },
  }),
);

export const DashboardTopVisited = homePlugin.provide(
  createReactExtension({
    name: 'Top Visited',
    component: {
      lazy: () =>
        import('@backstage/plugin-home').then(m => m.HomePageTopVisited),
    },
    data: {
      'home.widget.config': {
        layout: {
          width: {
            defaultColumns: 6,
            minColumns: 4,
          },
          height: {
            defaultRows: 6,
            minRows: 6,
          },
        },
      },
    },
  }),
);

export const DashboardAnnouncements = homePlugin.provide(
  createReactExtension({
    name: 'Announcements',
    component: {
      lazy: () =>
        import('@internal/backstage-plugin-announcements').then(
          m => m.AnnouncementsCard,
        ),
    },
    data: {
      'home.widget.config': {
        layout: {
          width: {
            defaultColumns: 6,
            minColumns: 4,
          },
          height: {
            defaultRows: 6,
            minRows: 6,
          },
        },
      },
    },
  }),
) as any;

export const DashboardToolkit = homePlugin.provide(
  createCardExtension({
    name: 'DashboardToolkit',
    title: 'Quick Access',
    components: () => import('./widgets/Toolkit'),
    layout: {
      height: { minRows: 6, defaultRows: 6 },
      width: { minColumns: 4, defaultColumns: 6 },
    },
  }),
);
