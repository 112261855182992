import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useTextfieldStyles = makeStyles(
  (theme: Theme) => ({
    textfieldContainer: {
      //      display: 'flex',
      // flexDirection: 'column',
      // flex: 1,
      width: '100%',
      '& .v5-MuiInputLabel-root': {
        fontSize: '.875rem',
        fontWeight: 400,
      },

      '& .v5-MuiInputBase-root': {
        fontSize: '.875rem',
        fontWeight: 400,
        borderRadius: '8px',
        padding: '0',

        '&:hover .v5-MuiOutlinedInput-notchedOutline': {
          borderColor:
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.23)'
              : 'rgba(255, 255, 255, 0.23)',
        },
      },

      '& .v5-MuiInputBase-input': {
        padding: '12px',
      },

      '& .v5-MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',

        '& .v5-MuiListItemIcon-root': {
          minWidth: '36px',
        },
      },

      '& .v5-MuiInputBase-root.Mui-focused': {
        '& .v5-MuiOutlinedInput-notchedOutline': {
          borderColor:
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.23)'
              : 'rgba(255, 255, 255, 0.23)',
          borderWidth: '1px',
        },
      },

      '& .v5-MuiInputBase-root.Mui-error': {
        '& .v5-MuiOutlinedInput-notchedOutline': {
          borderColor: '#E22134',
          borderWidth: '1px',
        },
        '&:hover .v5-MuiOutlinedInput-notchedOutline': {
          borderColor: '#E22134',
        },
      },

      '& .v5-MuiFormHelperText-root.Mui-error': {
        fontSize: '.875rem',
        margin: 0,
        paddingTop: theme.spacing(1),
        color: '#EE4C40',
      },
    },
    field: {
      backgroundColor: theme.palette.mode === 'dark' ? '#383838' : '#E6E6E6',
      borderRadius: '.5rem',
      border: 'none',
      '& .v5-MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    // tooltipWrapper: {
    //   maxWidth: '163px',
    //   '& .v5-MuiTooltip-tooltipArrow': {
    //     fontSize: '0.75rem',
    //     padding: '8px',
    //     backgroundColor: '#333',

    //     '& .v5-MuiTooltip-arrow': {
    //       color: '#333',
    //     },
    //   },
    // },
    labelWrapper: {
      display: 'flex',
      justifyContent: 'normal',
      gap: '4px',
    },
    required: {
      color: 'var(--Sun-Red-400, #FE786E)',
      fontSize: '14px',
    },
    label: {
      paddingBottom: '8px',
      '&.v5-MuiFormLabel-root': {
        color: theme.palette.mode === 'dark' ? '#fff' : '#333333',
      },
    },
    iconWrapper: {
      padding: '3px 8px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    helpIcon: {
      color: '#00D181',
      fontSize: 18,
    },
    errorMessage: {
      color: '#FE6358',
      fontSize: '.875rem',
      paddingTop: '.5rem',
    },
    characterCounter: {
      fontSize: '12px',
      marginTop: '4px',
      textAlign: 'right',
      color: theme.palette.text.secondary, // Default gray
      '&.error': {
        color: theme.palette.error.main, // Red when limit is reached
      },
    },
  }),
  { name: 'textfieldEle' },
);
