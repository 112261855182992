import React, { createContext, PropsWithChildren, useReducer } from 'react';

import { ACTIONS } from '../constants/announcement.constant';
import {
  AnnouncementActions,
  AnnouncementState,
} from '../types/announcement.types';

const initialState: AnnouncementState = {
  selectedTab: 'all-updates',
  refresh: false,
  markAsAllRead: false,
  searchFilter: '',
  selectedFilters: {},
};

export const announcementReducer = (
  state: AnnouncementState,
  action: AnnouncementActions,
) => {
  switch (action.type) {
    case ACTIONS.SELECT_TAB:
      return {
        ...state,
        selectedTab: action.payload,
        selectedFilters: {},
      };

    case ACTIONS.REFRESH_COUNT:
      return { ...state, refresh: !state.refresh };

    case ACTIONS.MARK_AS_ALL_READ:
      return { ...state, markAsAllRead: !state.markAsAllRead };
    case ACTIONS.SET_SEARCH_FILTER: {
      return {
        ...state,
        searchFilter: action.payload,
      };
    }
    case ACTIONS.SET_FILTER: {
      const { filter, values } = action.payload;
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [filter]: values,
        },
      };
    }
    case ACTIONS.CLEAR_FILTER: {
      const { filter } = action.payload;
      const cleanedFilters = Object.fromEntries(
        Object.entries(state.selectedFilters).filter(
          ([key, _]) => key !== filter,
        ),
      );

      return {
        ...state,
        selectedFilters: cleanedFilters,
      };
    }

    default:
      return state;
  }
};

export const AnnouncementContext = createContext<{
  state: AnnouncementState;
  dispatch: React.Dispatch<AnnouncementActions>;
}>({ state: initialState, dispatch: () => null });

export const AnnouncementProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(announcementReducer, initialState);
  const valueObject = { state, dispatch };
  return (
    <AnnouncementContext.Provider value={valueObject}>
      {children}
    </AnnouncementContext.Provider>
  );
};
