import React from 'react';

import { CustomFilterSection } from './CustomFilterSection';
import { GROUPPICKER } from '../constants/announcement.constant';

type FilterOption = {
  label: string;
  value: string;
};
interface GroupPickerProps {
  filterOptions: FilterOption[];
}

export const GroupPicker = ({ filterOptions }: GroupPickerProps) => {
  return (
    <CustomFilterSection
      label={GROUPPICKER.title}
      path={GROUPPICKER.kind}
      filterOptions={filterOptions || []}
      isSearchEnabled
    />
  );
};
