import React, { useContext } from 'react';

import { SearchFilter } from './SearchFilter';
import { useFilterSectionWrapperStyles } from '../styles/filter-section-wrapper.styles';
import { AUTHORPICKER, GROUPPICKER } from '../constants/announcement.constant';
import {
  useCategories,
  useFetchFilterOptions,
} from '@internal/backstage-plugin-announcements-react';
import { GroupPicker } from './GroupPicker';
import { AnnouncementContext } from '../state/announcement.state';
import { NAVIGATION_ITEMS } from '@internal/backstage-plugin-announcements-common';
import { FilterOption } from '../types/announcement.types';
import { CategoryPicker } from './CategoryPicker';
import { AuthorPicker } from './AuthorPicker';

export const FilterSectionWrapper = ({
  hideGroupPicker = false,
  entitiyPageGroupRef = '',
}) => {
  const classes = useFilterSectionWrapperStyles();
  const {
    state: { selectedTab },
  } = useContext(AnnouncementContext);

  const filterByUser = selectedTab === NAVIGATION_ITEMS.MY_UPDATES.value;
  const group =
    entitiyPageGroupRef ||
    (selectedTab === NAVIGATION_ITEMS.XELERATE_UPDATES.value
      ? selectedTab
      : '');
  const { value: groupOptions } = useFetchFilterOptions(
    GROUPPICKER.kind,
    filterByUser,
  );
  const { categories } = useCategories();
  const categoryOptions: FilterOption[] = categories?.map(category => ({
    label: category.title,
    value: category.slug,
  }));
  const { value: authorOptions } = useFetchFilterOptions(
    AUTHORPICKER.kind,
    filterByUser,
    group,
  );

  return (
    <div className={classes.filterSectionContainer}>
      <div className="filterSection">
        {!hideGroupPicker &&
          groupOptions &&
          // groupOptions.length > 1 &&
          selectedTab !== NAVIGATION_ITEMS.XELERATE_UPDATES.value && (
            <GroupPicker
              filterOptions={groupOptions || []}
              key={`${selectedTab}-group`}
            />
          )}

        {/* as per the discussion author filter should not be visible on the my updates tab   */}

        {!filterByUser && (
          <AuthorPicker
            filterOptions={authorOptions || []}
            key={`${selectedTab}-author`}
          />
        )}

        <CategoryPicker
          filterOptions={categoryOptions || []}
          key={`${selectedTab}-category`}
        />
      </div>

      <div className="filterSection">
        <SearchFilter />
      </div>
    </div>
  );
};
