import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermission } from '@backstage/plugin-permission-react';
import {
  // announcementUpdatePermission,
  // announcementDeletePermission,
  Announcement,
  announcementOwnerDeletePermission,
  announcementOwnerUpdatePermission,
} from '@internal/backstage-plugin-announcements-common';
import { DateTime } from 'luxon';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { parseEntityRef } from '@backstage/catalog-model';
import {
  EntityDisplayName,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';

import {
  announcementEditRouteRef,
  announcementViewRouteRef,
  rootRouteRef,
} from '../../routes';
import { announcementsApiRef } from '@internal/backstage-plugin-announcements-react';
import { IconButton } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AnnouncementSubtitle } from '../commons/AnnouncementSubtitle';
import { DeleteIcon, DotIcon, EditIcon } from '../../assets/Icons';
import { AnnouncementContext } from './state/announcement.state';
import { useAnnoucementCardStyles } from './styles/announcement-card.styles';

/**
 * Truncate text to a given length and add ellipsis
 * @param text the text to truncate
 * @param length the length to truncate to
 * @returns the truncated text
 */
// const truncate = (text: string, length: number) => {
//   return text.length > length ? `${text.substring(0, length)}...` : text;
// };
type AnnouncementCardProps = {
  titleLength?: number;
};
export const AnnouncementCard = ({
  announcement,
  onDelete,
  // options: { titleLength = 70 },
}: {
  announcement: Announcement;
  onDelete: () => void;
  options: AnnouncementCardProps;
}) => {
  const classes = useAnnoucementCardStyles();
  const announcementsLink = useRouteRef(rootRouteRef);
  const viewAnnouncementLink = useRouteRef(announcementViewRouteRef);
  const editAnnouncementLink = useRouteRef(announcementEditRouteRef);
  const entityLink = useRouteRef(entityRouteRef);
  const announcementsApi = useApi(announcementsApiRef);
  const lastSeen = announcementsApi.lastSeenDate();
  const isNew = lastSeen < DateTime.fromISO(announcement.created_at);

  // const isNew = announcementsApi.isAnnouncementNew(announcement)
  const navigate = useNavigate();
  const handleEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(editAnnouncementLink({ id: announcement.id }));
  };
  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete();
  };

  const {
    state: { selectedTab },
  } = useContext(AnnouncementContext);

  // const title = (
  //   <Tooltip
  //     title={announcement.title}
  //     disableFocusListener
  //     data-testid="announcement-card-title-tooltip"
  //   >
  //     <Link to={viewAnnouncementLink({ id: announcement.id })}>
  //       {truncate(announcement.title, titleLength)}
  //     </Link>
  //   </Tooltip>
  // );
  const { loading: loadingDeletePermission, allowed: canDelete } =
    usePermission({
      permission: announcementOwnerDeletePermission,
      resourceRef: announcement.id,
    });
  const { loading: loadingUpdatePermission, allowed: canUpdate } =
    // usePermission({ permission: announcementUpdatePermission });
    usePermission({
      permission: announcementOwnerUpdatePermission,
      resourceRef: announcement.id,
    });
  const entityRef = parseEntityRef(announcement.publisherGroup);

  return (
    <Box
      className={classes.announcementcard}
      onClick={() => {
        navigate(viewAnnouncementLink({ id: announcement.id }));
      }}
    >
      <Box className={classes.announcementcardHeader}>
        <Typography variant="body2" className={classes.groupName}>
          <EntityDisplayName entityRef={entityRef} hideIcon disableTooltip />
        </Typography>

        <Box className={classes.announcementcardHeaderActions}>
          {!loadingUpdatePermission &&
            canUpdate &&
            // canUpdateByGroup &&
            selectedTab === 'my-updates' && (
              <IconButton
                style={{ padding: '0px' }}
                className={classes.actionButtons}
                aria-label="edit"
                onClick={handleEdit}
              >
                <EditIcon />
              </IconButton>
            )}
          {!loadingDeletePermission &&
            canDelete &&
            selectedTab === 'my-updates' && (
              <IconButton
                style={{ padding: '0px' }}
                className={classes.actionButtons}
                aria-label="delete"
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
        </Box>
      </Box>
      <Box className={classes.announcementcardHeaderName}>
        <Typography
          variant="h6"
          className={classes.announcementcardHeaderTitle}
        >
          {announcement.title}
        </Typography>
      </Box>
      <Box className={classes.announcementcardContent}>
        <Typography variant="h6" className={classes.announcementcardbody}>
          {announcement.excerpt}
        </Typography>
      </Box>
      <Box className={classes.announcementcardFooter}>
        <Box className={classes.announcementcardFooterDetails}>
          <Typography variant="body2" className={classes.announcementcardDate}>
            {DateTime.fromISO(announcement.created_at).toFormat('LLL d, yyyy')}
          </Typography>
          <DotIcon />
          <Typography
            sx={{ fontSize: '0.75rem' }}
            className={classes.announcementcardSubtitle}
          >
            <AnnouncementSubtitle
              publisherRef={announcement.publisher}
              entityLink={entityLink(parseEntityRef(announcement.publisher))}
              category={announcement.category}
              announcementsLink={announcementsLink()}
            />
          </Typography>
        </Box>
        {isNew && (
          <Box className={classes.announcementcardFooterChip}>
            <Typography className={classes.announcementcardFooterChipText}>
              New
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
