import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { stringifyEntityRef } from '@backstage/catalog-model';

type FilterOption = {
  label: string;
  value: string;
};

export const useFetchFilterOptions = (
  kind: string,
  filterByUser: boolean = false,
  group: string = '',
) => {
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  const fetchOptions = async (): Promise<FilterOption[]> => {
    const identity = await identityApi.getBackstageIdentity();
    const userEntityRef = identity.userEntityRef;

    let filter: Record<string, string | string[]> = { kind: kind };

    if (filterByUser) {
      if (kind === 'Group') {
        filter = {
          kind: 'Group',
          'relations.hasMember': userEntityRef,
        };
      } else if (kind === 'User') {
        // Fetch groups that the current user is a part of
        const userGroupsRes = await catalogApi.queryEntities({
          filter: {
            kind: 'Group',
            'relations.hasMember': userEntityRef,
          },
          fields: ['metadata.name', 'kind', 'metadata.namespace'],
        });

        const groupRefs = userGroupsRes.items.map(groups =>
          stringifyEntityRef(groups),
        );

        if (groupRefs.length > 0) {
          // Fetch users that are members of those groups
          filter = {
            kind: 'User',
            'relations.memberOf': groupRefs,
          };
        } else {
          // If the user is not part of any groups, return an empty result
          return [];
        }
      }
    }

    // to fetch users which are part of the groups
    if (group && kind === 'User') {
      filter = {
        kind: 'User',
        'relations.memberOf': group,
      };
    }

    const queryRes = await catalogApi.queryEntities({
      filter,
      fields: [
        'metadata.name',
        'metadata.title',
        'spec.profile.displayName',
        'kind',
      ],
    });

    return queryRes.items.map(entity => ({
      label:
        (entity.spec?.profile as { displayName?: string })?.displayName ||
        entity.metadata?.title ||
        entity.metadata?.name,
      value: stringifyEntityRef(entity),
    }));
  };

  const { loading, value, error } = useAsync(fetchOptions, [
    filterByUser,
    group,
  ]);

  return { loading, value, error };
};
