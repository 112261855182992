import React from 'react';
import { Link } from '@backstage/core-components';
import { EntityDisplayName } from '@backstage/plugin-catalog-react';
import { useAnnouncementsTranslation } from '@internal/backstage-plugin-announcements-react';
import { useAnnoucementSubtitle } from './annoucement-subtitle.styles';

interface AnnouncementSubtitleProps {
  publisherRef: string;
  entityLink: string;
  category?: { slug: string; title: string };
  announcementsLink: string;
}

export const AnnouncementSubtitle: React.FC<AnnouncementSubtitleProps> = ({
  publisherRef,
  entityLink,
  category,
}) => {
  const classes = useAnnoucementSubtitle();
  const { t } = useAnnouncementsTranslation();
  const handleLinkClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <Link
        className={`${classes.annouementcardSubtitle}__links`}
        to={entityLink}
        onClick={handleLinkClick}
      >
        <EntityDisplayName entityRef={publisherRef} hideIcon disableTooltip />
      </Link>

      {category && (
        <>
          {' '}
          <span className={classes.annouementcardSubtitle}>
            {t('announcementsPage.card.in')}{' '}
          </span>
          <span
            className={classes.annouementcardSubtitle}
            // to={`${announcementsLink}?category=${category.slug}`}
          >
            {category.title}
          </span>
        </>
      )}
    </>
  );
};
