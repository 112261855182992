import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useClearActiveButtonStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      display: 'flex',
      padding: '2px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px',
      background: theme.palette.mode === 'dark' ? '#333' : '#616161',
      borderRadius: '1000px',
    },
    label: {
      color: '#fff',
      fontSize: '.75rem',
      fontWeight: 500,
      lineHeight: '133%',
    },
    clear: {
      color: '#fff',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  }),
  { name: 'ClearActiveButton' },
);
