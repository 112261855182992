import React, { ReactNode, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@material-ui/core/styles/styled';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

const AccordionContainer = styled('div')(({}) => ({
  overflow: 'hidden',
  backgroundColor: 'inherit',
}));

const AccordionHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  gap: '8px',
  cursor: 'pointer',
  color: theme.palette.type === 'light' ? 'var(--UI-Gray-20, #333)' : '#FFF',
  backgroundColor: 'inherit',
}));

const AccordionContent = styled('div')(({}) => ({
  padding: '0px',
  backgroundColor: 'inherit',
}));

interface CustomAccordionProps {
  title: string;
  children: ReactNode;
}

const CustomAccordion = ({ title, children }: CustomAccordionProps) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <AccordionContainer>
      <AccordionHeader onClick={() => setExpanded(!expanded)}>
        <IconButton size="small">
          <ExpandMoreIcon
            style={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          />
        </IconButton>
        <Typography variant="body2">{title}</Typography>
      </AccordionHeader>
      <Collapse in={expanded}>
        <AccordionContent>{children}</AccordionContent>
      </Collapse>
    </AccordionContainer>
  );
};

export default CustomAccordion;
