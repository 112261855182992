import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useUnreadAnnouncementsStyles = makeStyles(
  (_: Theme) => ({
    kindItemCountContainer: {
      display: 'flex',
      width: '20px',
      height: '20px',
      // padding: '.125rem .5rem',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '1000px',
      background: 'var(--Future-Green-500, #2EFFAF)',

      fontSize: '.625rem',
      fontWeight: 700,
      color: 'var(--UI-Gray-25, #404040)',
      marginLeft: '25px',
    },
  }),
  { name: 'UnReadAnnoucements' },
);
