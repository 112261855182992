import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material/styles';

export const useAnnoucementsGridStyles = makeStyles(
  (theme: Theme) => ({
    gridContainerWrapper: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 86px - 55px - 67px)',
      '@media (max-width: 1302px)': {
        maxHeight: 'calc(100vh - 86px - 55px - 115px)',
      },
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.background.default,
        scrollbarWidth: 'inherit',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.paper,
        outline: `1px solid ${theme.palette.divider}`,
      },
      '&::-webkit-scrollbar-thumb': {
        maxWidth: '4px',
        backgroundColor: theme.palette.grey[500],
        borderRadius: '10px',
        border: `2px solid ${theme.palette.background.paper}`,
      },
    },
    cardContainer: {
      padding: '0 1rem 1rem',
      color: theme.palette.mode === 'dark' ? '#FFF' : '#333',
      // display: 'flex',
      // flexWrap: 'wrap',
    },
  }),
  { name: 'AnnoucementsGrid' },
);
