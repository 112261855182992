import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useUnreadAnnouncementsStyles } from './styles/unread-announcement.styles';
import {
  announcementsApiRef,
  useAnnouncements,
} from '@internal/backstage-plugin-announcements-react';
import { DateTime } from 'luxon';
import { useApi } from '@backstage/core-plugin-api';
import {
  Announcement,
  AnnouncementSignal,
  SIGNALS_CHANNEL_ANNOUNCEMENTS,
} from '@internal/backstage-plugin-announcements-common';
import { useSignal } from '@backstage/plugin-signals-react';
// import { AnnouncementProvider } from './state/announcement.state';

export const UnReadAnnoucement = () => {
  const classes = useUnreadAnnouncementsStyles();
  const announcementsApi = useApi(announcementsApiRef);

  // const lastSeen = announcementsApi.lastSeenDate();
  // const [lastSeen, setLastSeen] = useState(announcementsApi.lastSeenDate());
  const [signaledAnnouncement, setSignaledAnnouncement] = useState<
    Announcement | undefined
  >();

  const { lastSignal } = useSignal<AnnouncementSignal>(
    SIGNALS_CHANNEL_ANNOUNCEMENTS,
  );

  const { announcements, retry: refresh } = useAnnouncements({});
  useEffect(() => {
    if (!lastSignal || lastSignal.data === signaledAnnouncement) {
      return;
    }

    setSignaledAnnouncement(lastSignal?.data);
    refresh();
  }, [lastSignal, refresh, signaledAnnouncement]);

  const unseenAnnouncements = announcements.results.filter(announcement => {
    return (
      announcementsApi.lastSeenDate() <
      DateTime.fromISO(announcement.created_at)
    );
  });

  if (unseenAnnouncements?.length === 0) {
    return null;
  }

  return (
    <Box className={classes.kindItemCountContainer}>
      {unseenAnnouncements?.length}
    </Box>
  );
};
