import React from 'react';
import Typography from '@mui/material/Typography';
import { useClearActiveButtonStyles } from '../styles/clear-active-button.styles';
import { ClearIcon } from '../../../assets/Icons';

interface ClearActiveButtonProps {
  activeItems: string;
  onClear: () => void;
}

const ClearActiveButton = (props: ClearActiveButtonProps) => {
  const { activeItems, onClear } = props;
  const classes = useClearActiveButtonStyles();
  return (
    <div
      data-testid="clear-button"
      onClick={event => {
        event.stopPropagation();
        onClear();
      }}
      onKeyPress={event => {
        event.stopPropagation();
        onClear();
      }}
      role="button"
      tabIndex={0}
      className={classes.button}
    >
      <Typography className={classes.label} data-testid="clear-button-items">
        {activeItems}
      </Typography>
      <ClearIcon />
    </div>
  );
};

export default ClearActiveButton;
