import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useTextfieldStyles } from './styles/textfield.styles';

interface TextfieldProps {
  name: string;
  label: string;
  placeholder?: string;
  tooltip?: string;
  actionItem?: React.ReactNode;
  isSelect?: boolean;
  selectOption?: { label: string | React.ReactNode; value: string }[];
  hideLabel?: boolean;
  [x: string]: any;
}

export const Textfield = React.forwardRef<HTMLInputElement, TextfieldProps>(
  (
    {
      name,
      label,
      placeholder = '',
      tooltip,
      actionItem,
      isSelect = false,
      selectOption = [],
      error,
      helperText,
      hideLabel = false,
      ...restProps
    },
    ref,
  ) => {
    const classes = useTextfieldStyles();

    return (
      <div className={classes.textfieldContainer}>
        {!hideLabel && (
          <div className={classes.labelWrapper}>
            <InputLabel htmlFor={name} className={classes.label}>
              {label}
            </InputLabel>
            <Typography variant="body2" className={classes.required}>
              *
            </Typography>
            {/* {actionItem && actionItem} */}
          </div>
        )}
        <TextField
          id={name}
          placeholder={placeholder}
          fullWidth
          ref={ref}
          className={classes.field}
          {...restProps}
        />
        {error && (
          <Typography className={classes.errorMessage}>{helperText}</Typography>
        )}
      </div>
    );
  },
);
