import React from 'react';

import { CustomFilterSection } from './CustomFilterSection';
import { AUTHORPICKER } from '../constants/announcement.constant';
import { FilterOption } from '../types/announcement.types';

interface AuthorPickerProps {
  filterOptions: FilterOption[];
}

export const AuthorPicker = ({ filterOptions }: AuthorPickerProps) => {
  return (
    <CustomFilterSection
      label={AUTHORPICKER.title}
      path={AUTHORPICKER.kind}
      filterOptions={filterOptions || []}
      isSearchEnabled
    />
  );
};
