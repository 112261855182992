import React from 'react';
import { Helmet } from 'react-helmet';
import { Page, Content } from '@backstage/core-components';
import { Header } from '@backstage/core-components';
import { WelcomeTitle, CustomHomepageGrid } from '@backstage/plugin-home';
import Box from '@mui/material/Box';
import {
  clockConfigs,
  timeFormat,
  // dashboardCtas,
  // dashboardCopy,
  defaultConfig,
} from './dashboard.constants';
import { getLanguageName } from './dashboard.utils';
import { useDashboardStyles } from './dashboard.styles';
// import CallToActionCard from '../reusable/call-to-action-card/CallToActionCard';
import HeaderWorldClock from '../reusable/world-clock/WorldClock';
import {
  /* DashboardStarredEntities, */
  DashboardRecentlyVisited,
  DashboardTopVisited,
  DashboardAnnouncements,
  DashboardToolkit,
  DashboardFavorites,
} from './dashboard.extension';
import { NewAnnouncementBanner } from '../announcements/NewAnnouncementBanner';

export const Dashboard = () => {
  const classes = useDashboardStyles();
  const language = getLanguageName(navigator.language);

  return (
    <Page themeId="dashboard">
      <Helmet titleTemplate="%s">
        <title>Home | XELERATE</title>
      </Helmet>
      <Header
        title={<WelcomeTitle language={[language]} />}
        pageTitleOverride="Home"
        type=""
      >
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>

      <Content>
        {/* Call-to-action card/banner commented out until further redesign */}
        {/* <Box className={classes.bannerContainer}>
          <CallToActionCard
            ctas={dashboardCtas}
            title={dashboardCopy.dashboardTitle}
            subtitle={dashboardCopy.dashboardSubtitle}
            gradient
            cardPadding="4rem 9.75rem"
            fullWidth
          />
        </Box> */}
        <Box>
          <NewAnnouncementBanner active />
        </Box>

        <Box className={classes.grid}>
          <CustomHomepageGrid config={defaultConfig}>
            {/* <DashboardStarredEntities title="Favorites" /> */}
            {/* Using cusotm favorites to get better styling on text and links*/}
            <DashboardFavorites />
            <DashboardRecentlyVisited numVisitsTotal={10} />
            <DashboardToolkit />
            <DashboardAnnouncements group="group:default/admins" />
            <DashboardTopVisited numVisitsTotal={10} />
          </CustomHomepageGrid>
        </Box>
      </Content>
    </Page>
  );
};
